import React from 'react';
import * as style from './simpleheader.module.scss';

const SimpleHeader = (props) => {
	return (
		<div className={style.container}>
			<div className={style.wrap}>
			
				<div className={style.top}>
					{props.top}
				</div>
				
				<h1 className={style.title}>{props.title}</h1>
				<p className={style.description}>{props.description}</p>
				
				{props.createdDate &&
					<p className={style.createdDate}>
						{new Intl.DateTimeFormat(
							'en-US',
							{
								year: 'numeric',
								month: 'long',
								day: '2-digit'
							}).format((props.createdDate * 1000))
						}
					</p>
				}
			</div>
		</div>
	)
};

export default SimpleHeader;