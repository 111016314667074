import React from 'react';
import * as style from './staticHero4x3.module.scss';

class StaticHero4x3 extends React.Component {
	render() {
		const inlineStyle = {
			backgroundImage: `url(${this.props.featuredImage.image.sizes.original})`,
		};

		const contain = this.props.contain ? style.maxWidth : '';
		const ratio = this.props.ratio ===  '4x3' ? 'ratio4x3' : '';
		const creditAlignment = this.props.creditAlignment ===  'left' ? 'text-left' : 'text-right';

		return (
			<div>
				<div
					className={`${style.container} ${contain} ${ratio} ${this.props.small ? style.smallHeight : ''}`}
					style={inlineStyle}
				>
					<div
						className={style.title}
					>
						{this.props.title}
					</div>
					
					<div className={style.description}>{this.props.description}</div>
				</div>
				
				<div className={`${style.creditCaptionContainer} ${contain}`}>
					{this.props.featuredImage.image.caption !== '' &&
						<div
							className={`${style.caption} ${creditAlignment}`}
							dangerouslySetInnerHTML={{ __html: this.props.featuredImage.image.caption }}
						>
						</div>
					}
					{this.props.featuredImage.image.credit_plain !== '' &&
						<div
							className={`${style.credit} ${creditAlignment}`}
						>
							{this.props.featuredImage.image.credit_plain}
						</div>
						
					}
				</div>
			</div>
		)
	}
}

export default StaticHero4x3;