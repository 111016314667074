// extracted by mini-css-extract-plugin
export var spacer = "project-module--spacer--TX+5y";
export var wrap = "project-module--wrap--diT9P";
export var wrapDark = "project-module--wrapDark--O9vwS";
export var container = "project-module--container--GR-dY";
export var cardGrid = "project-module--cardGrid--Mf2uQ";
export var normalTextBlock = "project-module--normalTextBlock--qYZXx";
export var paragraphs = "project-module--paragraphs--cq0aK";
export var articleFooter = "project-module--articleFooter--QKOu3";
export var inlineSocialShareMobile = "project-module--inlineSocialShareMobile--NqPcx";
export var shareBlock = "project-module--shareBlock--Qc5Uz";
export var shareArrowIcon = "project-module--shareArrowIcon--NUDxc";
export var shareText = "project-module--shareText--vaXaK";
export var threeColumn = "project-module--threeColumn--hLzlG";
export var relatedReading = "project-module--relatedReading--0PaxH";
export var center = "project-module--center--oojlc";
export var imageMaxWidth100 = "project-module--imageMaxWidth100--BoSOY";
export var centerButtons = "project-module--centerButtons--qfdSC";
export var clearBg = "project-module--clearBg--aUa23";
export var maxWidth1200 = "project-module--maxWidth1200--JLpdq";
export var logo = "project-module--logo--YKtUm";
export var sectionHeading = "project-module--sectionHeading--5wtGg";
export var fellowshipStories = "project-module--fellowshipStories--mSA-c";
export var sectionInset = "project-module--sectionInset--zfP1b";
export var sectionItem = "project-module--sectionItem--dxAY4";
export var storyImg = "project-module--storyImg--3G+np";
export var storyTitle = "project-module--storyTitle--pDfjm";
export var angle = "project-module--angle--wVkHu";
export var slideUp = "project-module--slideUp--dAAet";
export var slideUpLarge = "project-module--slideUpLarge--S-CG+";